import React from 'react'
import Layout from 'components/Layout'

const DataScienceServicePage = ({ location }) => (
  <Layout
    title="All the tools to unlock your data"
    description="Use UseData to connect to any data source (Redshift, BigQuery, MySQL, PostgreSQL, MongoDB and many others), query, visualize and share your data to make your company data driven."
    location={location}
  >
    <section className="section section-product-hero">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center m-b-100">
            <h1 className="serif">Data Science Services</h1>
            <p>
              We help enterprises gain insights from their massive amounts of data to drive business growth
            </p>
          </div>
        </div>

        <div className="row">
          <h3 className="m-t-0 feature_font service-container">
            <span className="m-r-15 feature_icon"><i className="fa fa-bolt" aria-hidden="true"></i></span> Capabilities
          </h3>          
          <div className="col-md-6">
            <div className="featured-customer case-study-item">
              <div className="row">
                <div className="col-md-4 service-capability">
                  <img
                    src="/assets/images/elements/data_collection.svg"
                    alt="Data Collection"
                    className="customer-logo"
                  />                  
                </div>
                <div className="col-md-8">
                  <h2>Data Collection</h2>
                  <ul className="highlight-ul">
                    <li>Structured and Unstructured Data</li>
                    <li>RDBMS & Big Data</li>
                    <li>Distributed File System (HDFS)</li>
                    <li>Flat file (text, csv, json, logs)</li>
                    <li>Websites & Web APIs</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="featured-customer case-study-item">
              <div className="row">
                <div className="col-md-4 service-capability">
                  <img
                    src="/assets/images/elements/data_processing.svg"
                    alt="Data Processing"
                    className="customer-logo"
                  />                  
                </div>
                <div className="col-md-8">
                  <h2>Data Processing</h2>
                  <ul className="highlight-ul">
                    <li>Data Cleansing</li>
                    <li>Data Normalization</li>
                    <li>Data Extraction</li>
                    <li>Data Transformation</li>
                    <li>Data Pipeline</li>
                    <li>Data Migration & Integration</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="featured-customer case-study-item">
              <div className="row">
                <div className="col-md-4 service-capability">
                  <img
                    src="/assets/images/elements/feature_engineering.svg"
                    alt="Feature Engineering"
                    className="customer-logo"
                  />                  
                </div>
                <div className="col-md-8">
                  <h2>Feature Engineering</h2>
                  <ul className="highlight-ul">
                    <li>Vectorization, Indexer</li>
                    <li>Feature Scaling</li>
                    <li>Feature Selection</li>
                    <li>Text Transformation</li>
                    <li>Natural Language Processing (NLP)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="featured-customer case-study-item">
              <div className="row">
                <div className="col-md-4 service-capability">
                  <img
                    src="/assets/images/elements/machine_learning.svg"
                    alt="Machine Learning"
                    className="customer-logo"
                  />                  
                </div>
                <div className="col-md-8">
                  <h2>Machine Learning</h2>
                  <ul className="highlight-ul">
                    <li>Regression Algorithms</li>
                    <li>Classification Algorithms</li>
                    <li>Ensemble & Boosting</li>
                    <li>Model Evaluation</li>
                    <li>Model Deployment</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>                    

        </div>
      </div>
    </section>

    <section className="section bg-soft-yellow">
      <div className="container">
        <div className="row">
          <h3 className="m-t-0 feature_font text-right service-container">
            <span className="m-r-15 feature_icon"><i className="fa fa-cogs" aria-hidden="true"></i></span> Technologies
          </h3>          
          <div className="col-md-4">
            <div className="featured-customer case-study-item">
              <h2>Platforms</h2>
              <ul className="highlight-ul">
                <li>Apache Spark</li>
                <li>Apache Hadoop</li>
              </ul>
            </div>
          </div>

          <div className="col-md-4">
            <div className="featured-customer case-study-item">
              <h2>Frameworks</h2>
              <ul className="highlight-ul">
                <li>Spark ML</li>
                <li>Scikit-Learn</li>
                <li>Tensorflow</li>
              </ul>
            </div>
          </div>

          <div className="col-md-4">
            <div className="featured-customer case-study-item">
              <h2>Data Stores</h2>
              <ul className="highlight-ul">
                <li>HBase</li>
                <li>Postgres</li>
                <li>ElasticSearch</li>
                <li>Redis</li>
              </ul>
            </div>
          </div>

          <div className="col-md-4">
            <div className="featured-customer case-study-item">
              <h2>Tools</h2>
              <ul className="highlight-ul">
                <li>Jupyter Notebooks</li>
                <li>R Studio</li>
              </ul>
            </div>
          </div>

          <div className="col-md-4">
            <div className="featured-customer case-study-item">
              <h2>Languages</h2>
              <ul className="highlight-ul">
                <li>Python</li>
                <li>R</li>
                <li>Java</li>
                <li>Scala</li>
              </ul>
            </div>
          </div>

          <div className="col-md-4">
            <div className="featured-customer case-study-item">
              <h2>Cloud Providers</h2>
              <ul className="highlight-ul">
                <li>AWS</li>
                <li>Goolge Cloud</li>
                <li>Heroku</li>
              </ul>
            </div>
          </div>                    

        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <h3 className="m-t-0 feature_font text-center mb-xs">
          <span className="m-r-15 feature_icon"><i className="fa fa-thumbs-up" aria-hidden="true"></i></span> Success Stories
        </h3>
        <p className="m-t-0 text-center mb-md">
          Unlock the value of Big Data to allow better data-driven decisions
        </p>
        <div className="row">
          <div className="col-md-4">
            <img src="/assets/images/elements/predictive_analytics.svg" width="64px" height="64px"/>
            <h2>Predictive Analytics</h2>
            <p>
              Design custom recommendation engine that reduces dimensionality and applies collaborative filtering to recognize patterns from historical, past behaviors and transactional data to make predictions about future business opportunities.
            </p>
          </div>

          <div className="col-md-4">
            <img src="/assets/images/elements/segment_analysis.svg" width="64px" height="64px"/>
            <h2>Customer Segmentation</h2>
            <p>
              Build single view of customer interactions to understand, segment and manage information in a more effective way to improve engagement and satisfaction.
            </p>
          </div>

          <div className="col-md-4">
            <img
              src="/assets/images/elements/social_dashboard.svg"
              width="64px"
              height="64px"
            />
            <h2>Social Listening & Analysis</h2>
            <p>
              Perform sentiment analysis of reviews and comments about products and services on various social media platforms.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="section section--pt-sm bg-soft-blue">
      <div className="container">
        
      </div>
    </section>

  </Layout>
)

export default DataScienceServicePage
